/*-------------------
  Testimonial Styles
---------------------*/
.home-one-testimonial {
    margin-top: 30px;
    @media #{$md-layout-1} {
        margin-top: 0px;
    }
}

.testimonial-heading-area {
    padding-right: 50px;
    @media #{$small-mobile} {
        padding-right: 0;       
    }
}
.testimonial-grid {
    box-shadow: var(--shadow-darker);
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 60px 30px 50px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:after {
        content: url(../../images/testimonial/shape-1.png);
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: -1;
    }
    .thumbnail {
        display: inline-block;
        position: relative;
        margin-bottom: 35px;
        img {
            border-radius: 50%;
        }
        .qoute-icon {
            height: 34px;
            width: 34px;
            line-height: 30px;
            font-size: 13px;
            color: var(--color-white);
            background-color: var(--color-primary);
            border-radius: 50%;
            border: 4px solid var(--color-white);
            display: block;
            text-align: center;
            position: absolute;
            bottom: -8px;
            right: -12px;
        }
    }
    .content {
        p {
            margin-bottom: 20px;
            font-size: 16px;
        }
        .rating-icon {
            margin-bottom: 6px;
            i {
                color: #f8b81f;
            }
        }
        .title {
            font-weight: var(--p-medium);
            margin-bottom: 2px;
        }
    }
    &.testimonial-style-3 {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 30px;
        &:after {
            display: none;
        }
    }
}

.testimonial-slide {
    .content {
        background-color: var(--color-white);
        box-shadow: var(--shadow-darker);
        // padding: 35px 28px 35px 40px;
        border-radius: 4px;
        margin-bottom: 30px;
        margin-top: 30px;
        .logo {
            margin-bottom: 22px;
        }
        p {
            color: var(--color-heading);
            font-size: 16px;
            margin-bottom: 15px;
        }
        .rating-icon {
            i {
                color: #f8b81f;
            }
        }
    }
    .author-info {
        display: flex;
        align-items: center;
        @media #{$small-mobile} {
            width: 50px;
            height: 50px; 
        }
        @media (max-width: 750px) {
            width: 60px;
            height: 60px; 
        }
        .thumb {
            margin-right: 20px;
            img {
                // border-radius: 50%;
                filter: grayscale(100%);
    transition: filter 0.3s ease, transform 0.3s ease; 

    &:hover {
        filter: grayscale(0%);
        transform: rotate(360deg); 
    }
            }
        }
        .info {
            flex: 1;
            .title {
                margin-bottom: 0;

            }

        }
    }
    &.testimonial-style-2 {
        .content {
            padding: 45px 40px 50px 50px;
            border-radius: 5px;
            @media #{$small-mobile} {
                padding: 40px 30px; 
            }
            .rating-icon {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 24px;
            }
        }
    }
    &.testimonial-style-3 {
        .content {
            background-color: transparent;
            padding: 20px;
            box-shadow: none;
            margin: 0;
            .rating-icon {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
}

.testimonial-area-2 {
    position: relative;

    .testimonial-activation {
        padding: 0 30px;
        margin: -30px -30px 0;
        margin-bottom: 50px;

        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-active,
            &.swiper-slide-next,
            &.swiper-slide-next + .swiper-slide {
                opacity: 1;
                visibility: visible;
            }
            &.swiper-slide-next + .swiper-slide {

                @media #{$md-layout-1} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.swiper-slide-next {
                @media #{$sm-layout} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    .swiper-pagination {
        bottom: -70px;
        display: flex;
        align-items: center;
        background-color: pink;
    }

    .shape-group {
        .shape-1 {
            top: 168px;
            right: -75px;
        }
        .shape-2 {
            top: 217px;
            right: -106px;
        }
        .shape-3 {
            bottom: 50px;
            left: 45%;
            transform: translateX(-50%);
        }
    }
    &.edu-section-gap {
        .shape-group {
            .shape-3 {
                bottom: 58px;
            }
        }
    }
}

// .testimonial-area-3 {
//     position: relative;
//     mask-image: url(../../images/others/mask-04.png);
//     -webkit-mask-repeat: no-repeat;
//     -webkit-mask-size: cover;
//     -webkit-mask-position: center;
//     background-color: var(--color-lighten04);
//     padding: 115px 0 250px;
//     @media #{$md-layout} {
//         padding: 95px 0 200px;
//     }
//     @media #{$sm-layout} {
//         padding: 75px 0 180px;
//     }
//     .testimonial-activation-2 {
//         margin: 0 -45px;
//         padding: 0 30px;

//         .testimonial-slide {
//             .content {
//                 margin-top: 0;
//                 margin-bottom: 50px;
//             }
//         }
//         .swiper-wrapper {
//             .swiper-slide {
//                 opacity: 0;
//                 visibility: hidden;

//                 &.swiper-slide-active,
//                 &.swiper-slide-next {
//                     opacity: 1;
//                     visibility: visible;
//                 }
//             }
//             .swiper-slide {
//                 @media #{$md-layout-1} {
//                     &.swiper-slide-next {
//                        opacity: 0;
//                     }
//                 }
//             }
//         }
//     }
//     .swiper-pagination {
//         bottom: 235px;
//         @media #{$md-layout-1} {
//             bottom: 170px;
//         }
//     }
//    .shape-group {
//         @media #{$sm-layout} {
//             display: none;
//         }
//         li {
//             &.shape-1 {
//                 top: 95px;
//                 left: 95px;
//             } 
//             &.shape-2 {
//                 bottom: 215px;
//                 left: 245px;
//             }
//             &.shape-3 {
//                 bottom: 150px;
//                 right: 170px;
//             }
//             &.shape-4 {
//                 top: 120px;
//                 right: 70px;
//             }
//             &.shape-5 {
//                 bottom: 140px;
//                 left: 50%;
//                 transform: translateX(-50%);

//                 img {
//                     transform: scaleX(1);
//                     opacity: 1;
//                 }
//             }
//         }
//     }
// }

.testimonial-area-4 {
    padding: 120px 0 70px;
    position: relative;
    @media #{$md-layout} {
        padding: 100px 0 50px;
    }
    .testimonial-heading-area {
        @media #{$md-layout-1} {
            padding-right: 0;
        }
        .section-title {
            &.section-left {
                @media #{$md-layout-1} {
                    text-align: center;
                }
            }
        }
    }
    .testimonial-activation-3 {
        margin: 0 -50px;
        padding: 0 30px;
        margin-top: -30px;
        margin-bottom: 10px;

        .swiper-slide {
            opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
        }

    }
    .shape-group {
        li {
            img {
                opacity: .2;
            }
            &.shape-1 {
                bottom: 60px;
                left: 42%;
            }
            &.shape-2 {
                bottom: 35px;
                right: 12%;
                img {
                    opacity: .7;
                }
            }
        }
    }
    .swiper-navigation {
        text-align: left;
        @media #{$md-layout-1} {
            text-align: center;
        }
    }
}
.testimonial-area-8 {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-image: url(../../images/bg/h8-testimonial-bg.svg);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
    }
    .testimonial-activation {
        padding: 0 30px;
        margin: -30px -30px 0;
        margin-bottom: 50px;

        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-active,
            &.swiper-slide-next,
            &.swiper-slide-next + .swiper-slide {
                opacity: 1;
                visibility: visible;
            }
            &.swiper-slide-next + .swiper-slide {

                @media #{$md-layout-1} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.swiper-slide-next {
                @media #{$sm-layout} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .testimonial-slide {
        margin-top: 93px;
        .author-info {
            display: block;

            .thumb {
                margin-right: 0;
                margin-top: -100px;
                margin-bottom: 35px;
            }
        }
        .content {
            text-align: center;
            margin-bottom: 45px;
            border-radius:10px;
            p{
                margin-bottom: 30px;
            }
            .info {
                .title {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .shape-group {
        li {
            &.shape-3 {
                bottom: 109px;
                left: 50%;
                z-index: -1;
                transform: translateX(-50%);
            }
        }
    }
    .swiper-horizontal>.swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal, 
    .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -30px;
    }
}
.testimonial-area-9  {
    background: var(--color-lighten01);

    .testimonial-heading-area {
        width: 380px;
        padding-right: 0;
        padding-top: 50px;
        position: relative;
        z-index: 12;
        @media #{$md-layout-1} {
            width: 100%;
            text-align: center;
            padding-top: 0;
        }
        .section-left {
            @media #{$md-layout-1} {
                text-align: center;
            }
        }
    }
    .bg-thumbnail {
        @media #{$md-layout-1} {
            text-align: center;
        }
        .shape-group {
            li {
                &.shape-3 {
                    left: -41px;
                    bottom: -87px;
                }
                &.shape-4 {
                    top: -80px;
                    right: -105px;
                }
                &.shape-5 {
                    left: -54px;
                    bottom: -18px;
                    z-index: 1;
                }
            }
        }
    }
    .health-bg-thumbnail {
        margin-left: 34px;
        @media #{$md-layout-1} {
            margin-left: 0;
        }
    }
    .home-health-testimonial-activator {
        margin-top: 110px;
        padding-left: 20px;
        @media #{$lg-layout} {
            margin-top: 0;
        }
        @media #{$md-layout-1} {
            margin-top: 0;
            padding-left: 0;
            text-align: center;
        }
        .testimonial-grid {
            background-color: transparent;
            box-shadow: none;
            &::after {
                display: none;
            }
            .thumbnail {
                margin-bottom: 25px;
            }
            .content {
                .title {
                    font-weight: var(--p-semi-bold);
                }
                p {
                    color: var(--color-heading);
                }
            }
        }
        .swiper-navigation {
            position: relative;
            bottom: 25px;
            left: 44px;
            z-index: 12;
            @media #{$md-layout-1} {
                left: 0;
                bottom: 20px;
            }
            .swiper-btn-prv,
            .swiper-btn-nxt {
                line-height: 57px;
                &:hover {
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(101%) contrast(103%);
                    }
                }
                @media #{$sm-layout} {
                    line-height: 45px;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                bottom: 0px;
                left: -169px;
            }
            &.shape-2 {
                top: -91px;
                right: -150px;
            }
        }
    }
}
  
.testimonial-coverflow {
    margin: -55px 0;
    .testimonial-grid {
        margin: 55px 0;
        position: relative;
        @media #{$large-mobile} {
            margin: 55px 30px;
        }
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            background-color: var(--color-white);
            opacity: .7;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            transition: var(--transition);
        }
    }
    .swiper-slide {
        padding: 0;

        &.swiper-slide-visible {
            &.swiper-slide-active {
               .testimonial-grid {
                    &:before {
                        opacity: 0;
                    }
               }
            }
        }
    }
}

.swiper-testimonial-slider-wrapper {
    .swiper-slide {
        opacity: 0;
    }
    .swiper-slide {
        &.swiper-slide-visible {
            opacity: 1;
            &.swiper-slide-prev {
                .testimonial-grid {
                    margin-left: 36px;
                    box-shadow: 70px 0px 50px 0px rgba(26, 46, 85, .1);
                    padding: 40px 30px 50px;
                    @media #{$smlg-device} {
                        margin-left: 0px;
                    }
                    @media #{$md-layout} {
                        margin-left: 40px;
                    }
                }
            }
            &.swiper-slide-next {
                .testimonial-grid {
                    margin-left: 40px;
                    margin-right: 36px;
                    box-shadow: -40px 0px 50px 0px rgba(26, 46, 85, .1);
                    padding: 40px 30px 50px;
                    @media #{$smlg-device} {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    @media #{$md-layout} {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

.testimonial-area-1 {
    margin-bottom: -40px;
    @media #{$smlg-device} {
        margin-top: 0px;
    }
    .home-one-testimonial-activator {
        padding: 0 30px;
        margin: 0 -45px;
        margin-top: -20px;

        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .testimonial-grid {
            margin: 30px 0px 50px 0px;
        }
    }
}


.swiper-pagination {
    display: flex;
   flex-direction: row;
   justify-content: center;
   margin-top: 0.5rem;
    .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        background-color: var(--color-secondary);
        opacity: 1;
        margin: 0 8px !important;
        border: purple 1px solid;
        &.swiper-pagination-bullet-active {
            background-color: var(--color-secondary);
        }
    }
}

.testimonial-area-6 {
    position: relative;
    &:before {
        content: "";
        height: 925px;
        width: 100%;
        background: linear-gradient(-90deg, #f5f1eb 0%, rgba(245, 241, 235, .3) 100%);
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @media #{$md-layout} {
            height: 55%;
        }
        @media #{$sm-layout} {
            height: 55%;
        }
    }
    .testimonial-activation-5 {
        .swiper-wrapper {
            .swiper-slide {
                padding: 0;
            }
        }
    }
   
    .swiper-pagination {
        bottom: 30px;
        text-align: center;
    }

    .shape-group {
        li {
            &.shape-1 {
                bottom: 80px;
                left: -100px;
            } 
            &.shape-2 {
                top: -50px;
                right: -94px;
            }
            &.shape-3 {
                top: -200px;
                right: -250px;
                span {
                    height: 400px;
                    width: 400px;
                    border: 1px solid #e8e6e3;
                    border-radius: 50%;
                    display: block;
                }
            }
        }
    }
}
.testimonial-area-10 {
    background: var(--bg-color);
    overflow: hidden;

    .testimonial-heading-area {
        padding-right: 0;
    }
    .home-language-testimonial-activator {
        max-width: 770px;
        .testimonial-grid {
            box-shadow: none;
            border-radius: 5px;
            text-align: center;
            padding: 45px 64px 80px;
            @media #{$small-mobile} {
                padding: 45px 30px 80px;
            }
            &::after {
                display: none;
            }
            .content {
                .rating-icon {
                    margin-bottom: 15px;
                }
                p {
                    color: var(--color-heading);
                }
                .title {
                    font-weight: var(--p-semi-bold);
                }
            }
        }
    }
    .testimonial-thumb-wrap {
        margin: -56px auto 0;
        max-width: 400px;
        text-align: center;
        width: 100%;
        position: relative;
        left: 20px;

        .nav-thumb {
            align-items: center;
            display: flex;
            flex-direction: column;
            .clint-thumb {
                img {
                    cursor: pointer;
                    border-radius: 50%;
                    transform: scale(.6667);
                }
            }
            &.swiper-slide-active {
                margin: 0 -20px;
                .clint-thumb {
                    img {
                        transform: scale(1.1); 
                        padding: 6px;
                    }
                }
            }
        }
        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-prev,
            &.swiper-slide-active,
            &.swiper-slide-next {
                opacity: 1;
                visibility: visible;
            } 
            &.swiper-slide-prev,
            &.swiper-slide-next {
                @media (max-width:499px) {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: 120px;
                top: 145px;
            }
            &.shape-2 {
                left: 85px;
                top: 43%;
                span {
                    width: 70px;
                    height: 70px;
                    background: var(--color-tertiary);
                    border-radius: 100%;
                }
            }
            &.shape-3 {
                left: -55px;
                top: 13px;
                span {
                    display: block;
                    height: 400px;
                    width: 400px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
        }
    }
    .shape-group {
        .shape-4 {
            right: 248px;
            top: 170px;
            z-index: 1;
        }
        .shape-5 {
            right: -105px;
            top: -118px;
            span {
                display: block;
                height: 470px;
                width: 470px;
                border: 1px solid var(--color-border);
                border-radius: 50%;
            }
        }
    }
}
.testimonial-area-11 {
    z-index: 1;
    .home-remote-testimonial-activator {
        max-width: 870px;
        padding: 0 30px;
        margin-top: -35px;
        @media #{$large-mobile} {
            padding: 0 10px;
        }

        .swiper-slide {
            opacity: 1;
            visibility: visible;
            &.swiper-slide-prev,
            &.swiper-slide-next {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .testimonial-slide {
        margin-top: 93px;
        .author-info {
            display: block;

            .thumb {
                margin-right: 0;
                margin-top: -100px;
                margin-bottom: 35px;
            }
        }
        .content {
            width: 770px;
            text-align: center;
            margin-bottom: 45px;
            border-radius:10px;
            padding: 40px 95px;
            @media #{$md-layout-1} {
                width: 100%;
                padding: 40px 50px;
            }
            @media #{$small-mobile} {
                padding: 40px 20px;
            }
            p{
                margin-bottom: 20px;
            }
            .info {
                .title {
                    margin-bottom: 5px;
                }
            }
        }
    }
    .swiper-horizontal>.swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal, 
    .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -30px;
    }
    .shape-group {
        li {
            &.shape-1 {
                left: 107px;
                bottom: -50px;
            }
            &.shape-2 {
                left: 70px;
                bottom: 29px;
                span {
                    width: 70px;
                    height: 70px;
                    background: var(--color-tertiary);
                    border-radius: 100%;
                }
            }
            &.shape-3 {
                left: -28px;
                bottom: -235px;
                z-index: -1;
                span {
                    display: block;
                    height: 400px;
                    width: 400px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
        }
    }
    .shape-group {
        .shape-4 {
            right: 248px;
            top: 170px;
            z-index: 1;
        }
        .shape-5 {
            right: 0;
            top: 0;
        }
    }
}