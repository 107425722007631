.singleVideo{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    overflow-x: hidden;
  

    .desc{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
        .arrow{

            flex-grow: inherit;
            flex-wrap: wrap;
            transform: rotate(90deg);
        }
        .description{
margin: 1rem 0 3rem 0;
        }
    }
}