* {
    margin: 0;
    box-sizing: border-box;
  }

  
  @media (min-width: 1180px) {
    body .css-1w936jn {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}
    /* .css-1wuilmg-MuiAutocomplete-root{
      width: 100% !important;
    }
  
    .MuiAutocomplete-hasPopupIcon.css-1wuilmg-MuiAutocomplete-root .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
      padding: 0 !important;
    }
  
    .css-1wuilmg-MuiAutocomplete-root .MuiInput-root .MuiInput-input{
      padding: 1.4rem !important;
    } */