.subCategory {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 4rem 0 2rem 2rem;
  // border-bottom: 4px solid purple;
  .subCatName {
    font-size: 40px;
    color: #040535;
    max-width: max-content;
  }
  .upper {
    padding: 20px 50px;
    display: flex;
    gap: 50px;

    .left {
      display: flex;
      gap: 20px;
      flex: 2;
      .images {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        border-radius: 8px;
          img {
            width: 45%;
            height: 100px;
            object-fit: cover;
            cursor: pointer;
            margin-top: 10px;
        
        }
      }

      .mainImg {
        flex: 5;
        background-color: #0f0f0fe7;
        position: relative;
        cursor: pointer;

        .videoSource {
          width: 100%;
          max-height: 800px;
          object-fit: cover;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .units {
        display: flex;
        flex-direction: column;

        .unit {
          font-size: 30px;
          font-weight: 900;
          color: purple;
        }

        .subUnits {
          display: flex;
          font-size: 24px;
          font-weight: 600;

          .subUnit {
            padding: 10px;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 300;
        text-align: justify;
      }

      .quantity {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
          width: 50px;
          cursor: pointer;
          border: none;
          height: 50px;
          justify-content: center;
          background: #ba68c8;
          font-weight: 700;
          font-size: 20px;
          align-items: center;
          display: flex;
          color: white;
        }

        .totalSelected {
          font-weight: 700;
        }
      }
      .color {
        display: flex;
        padding: 5px;
        height: 50px;

        select {
          font-size: 20px;
          color: white;
          width: 250px;

          .options {
            padding: 10px;
          }
        }
      }
      .size {
        width: 250px;
        display: flex;
        flex-direction: column;

        h3 {
          font-weight: 800;
          font-size: 24px;
          margin-bottom: 6px;
        }

        .item {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;

          span {
            background: #ba68c8;
            padding: 10px;
            color: white;
            cursor: pointer;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .add {
        width: 250px;
        background: #ba68c8;
        color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 20px;
        font-weight: 500;
        border: none;
        justify-content: center;
      }
      .links {
        display: flex;
        gap: 20px;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #ba68c8;
          font-size: 14px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 2rem;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .description {
        }

        .review {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: columyn;

          .reviewSlider {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            gap: 20px;
          }
        }
      }
    }
  }
  .lower {
  }
}
