.category{

  display: flex ;
  flex-direction: row;
  gap:1rem;
  width: 98% ;
  padding: 2rem 0 0 4rem ;


.left{
flex: 5;

  .header__section {
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #BA68C8;
   
  
  
  .header__text {
    color: white;
    font-size: 28px;
    font-weight: 800;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.introVideo{
min-height: 400px;
object-fit: contain;
background: #040535;

img{
  width: 100%;
}
}

.videoInCategory{
  margin-top: 4rem;
}

.displayCartegory{
  display: flex;
  flex-direction: row;
  padding: 0 4rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;

}

}

  .right{
    padding-top: 60px;
    display: flex;
    flex-direction: column;
  flex: 2;
  margin-top: 80px;
  position: sticky;
  height: 100%;
  top: 50px;
  align-items: center;

  .filterItem {
    margin-bottom: 30px;
    width: 60%;
 text-transform: capitalize;

    h2 {
      font-weight: 400;
      margin-bottom: 20px;
    }
h3{
 
}
    .inputItem {
      margin-bottom: 10px;
    }

    label {
      margin-left: 10px;
    }
  }
  }
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .category {
    flex-direction: column-reverse;
    padding: 2rem;

    .left {
      padding-right: 0;
      margin-bottom: 2rem;

      .displayCartegory {
        padding: 0;
      }
    }

    .right {
      margin-top: 2rem;
      width: 100%;
      position: fixed;
      background-color: pink;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;



      .filterItem {
    height: 60px;
    background: black;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 12px;
    align-items: center;
    text-align: center;
    padding: 1px;
    justify-content: center;
    flex-wrap: wrap;

    .css-1hb0sn3-MuiTypography-root{
width: auto;
font-size: 12px;
    }
      }
    }
  }
}