/*-------------------
 * Button
---------------------*/
a,
button {
    &.btn-transparent {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--color-textSecondary);
        display: inline-block;
        position: relative;
        transition: 0.3s;
        &::after {
            position: absolute;
            content: "";
            left: auto;
            right: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background: var(--color-primary);
            transition: 0.3s;
        }

        i {
            padding-left: 6px;
            top: -1px;
            position: relative;
            font-size: 12px;
        }
        &:hover {
            color: var(--color-primary);
            &::after {
                width: 100%;
                left: 0;
                right: auto;
            }
        }
    }
}

a,
button {
    &.edu-btn {
        text-align: center;
        border-radius: 5px;
        display: inline-block;
        height: 60px;
        line-height: 62px;
        color: var(--edu-btn-color);
        background: var(--color-primary);
        padding: 0 30px;
        font-size: 15px;
        font-weight: 500;
        transition: 0.4s;
        font-family: var(--font-secondary);
        border: 0 none;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @media #{$sm-layout} {
            padding: 0 20px;
            font-size: 14px;
            height: 50px;
            line-height: 52px;
        }
        &:after {
            content: "";
            height: 100%;
            width: 0;
            background: linear-gradient(-90deg, #cfa6e7 0%, #561997 100%); 
            border-radius: 5px;
            position: absolute; 
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            transition: 0.4s;
        }
        i {
            padding-left: 6px;
            position: relative;
            font-size: 11px;
            @media #{$sm-layout} {
                font-size: 9px;
            }
        }

        &:hover {
            &:after {
                left: 0;
                width: 100%;
            }
        }
        
        &.btn-large {
            padding: 0 40px;
            i {
                top: 0;
                padding-left: 10px;
            }
        }

        &.btn-medium {
            height: 50px;
            line-height: 51px;
            padding: 0 25px;
        }

        &.btn-small {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 14px;
        }

        &.left-icon {
            i {
                padding-left: 0;
                padding-right: 10px;
            }
        }

        &.btn-curved {
            border-radius: 30px 5px;
            &:hover {
                border-radius: 5px 30px;
            }
        } 
        &.curved-medium {
            border-radius: 20px 5px;
            padding: 0 40px;
            &:hover {
                border-radius: 5px 25px;
            }
        }

        &.btn-secondary {
            background: var(--color-secondary);
            color: var(--color-white);
            &:hover {
                color: var(--color-white);
                background: var(--color-primary);
            }
        }
        &.btn-secondary1 {
            background: var(--color-secondary);
            color: var(--color-white);
            &:hover {
                color: var(--color-heading);
                background: var(--color-white);
            }
        }
        &.btn-border {
            background-color: transparent;
            border: 1px solid var(--color-border);
            color: var(--color-heading);
            padding: 0 25px;
            &:after {
                display: none;
            }
            &:hover {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}

.button-group {
    display: flex;
    align-items: center;
    margin: -5px;
    a {
        margin: 5px;
    }
}
.btn-gradient {
    background:linear-gradient(-90deg, #31b978 0%, #1ab69d 100%) !important;
    &::after {
        background: linear-gradient(-90deg, #1ab69d 0%, #31b978 100%) !important; 
    }
}
.wishlist-btn {
    background: rgba(255, 255, 255, 0.15);
    width: 40px;
    height: 40px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    border: 0 none;
    transition: var(--transition);
    i {
        color: var(--color-white);
        font-size: 19px;
        transition: var(--transition);
    }
    &:hover {
        background-color: var(--color-secondary);
    }
    &.btn-outline-dark {
        height: 50px;
        width: 50px;
        line-height: 55px;
        border: 1px solid #e5e5e5;
        color: var(--color-heading);
        pointer-events: all;
        i {
            color: var(--color-heading);
        }
        &:hover {
            border-color: var(--color-secondary);
            i {
                color: var(--color-white);
            }
        }
    }
}


.btn-icon-round {
    text-align: center;
    height: 60px;
    width: 60px;
    line-height: 60px;
    background-color: var(--color-primary);
    border-radius: 50%;
    color: var(--color-white);
    font-size: 12px;
    &:hover {
        color: var(--color-white);
        background-color: var(--color-secondary);
    }
}
          

 